import React from 'react'
import PropTypes from 'prop-types'
import Author from './Author';

import Linked from '../elements/Linked';
import  Img from 'gatsby-image';

const BlogPost = ({ title, description, author, coverImage, published, readingTime, slug }) => {
    return (
        <Linked linkTo={`/blog/${slug}`} >
        <article className="rounded shadow hover:shadow-xl transition-shadow duration-300 cursor-pointer bg-white h-full  flex flex-col">
            <div className="bg-primary rounded-t">
                <Img className="w-full h-56 flex rounded-t sm:rounded-r" fluid={coverImage.fluid} />
            </div>
            <div className="pt-4 px-4">
                <h3 className="text-2xl tracking-wide mb-1">{title}</h3>
                <p className="text-sm leading-relaxed font-thin">{description}</p>
            </div>
            <div className="px-4 pb-4 flex h-full">
                <Author className="w-full self-end" author={author} published={published} readingTime={readingTime} />
            </div>
        </article>
        </Linked>
    )
}

BlogPost.propTypes = {

}

BlogPost.defaultProps = {
    title: "Blog Post Title",
    description: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Non, vitae maxime. Reprehenderit sint facilis excepturi modi architecto illum alias.",
    published: "30th May 2020",
    coverImage: "https://images.ctfassets.net/9h27vede8di8/13Y82RTprSsYvUrCHem1GU/fe179cba963a0019a8b7cdb2a9cfc4d3/mercedes-benz-2498264_1920.jpg?w=2880&&q=80",
}

export default BlogPost
