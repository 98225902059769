import React from 'react'
import PropTypes from 'prop-types'
import Img from 'gatsby-image';

const Author = ({ author, published, readingTime, className, ...restProps }) => {

    const { name, photo  } = author
    return (
        <aside className={`mt-2 grid grid-cols-8 ${className}`}>
            <div className="col-span-2 flex flex-col justify-center">
            <Img className=" mx-auto bg-gray-400 rounded-full" fixed={photo.fixed} />
            </div>
            <div className="col-span-6">
                <h4 className="text-sm ">{name}</h4>
                <p className="text-xs -mb-1 font-thin">{published} - {readingTime}</p>
            </div>
        </aside>
    )
}

Author.propTypes = {

}
Author.defaultProps = {
    published: "27-07-2020",
    readingTime: "5 mins",
    author: {
        fields: {
            name: "Sam Loyd",
            photo: "https://images.ctfassets.net/9h27vede8di8/1wsuTBojNNPDyfdpUNi9Vf/7e8ca14ce8ed925da17df05b03186918/headhsot-2-square.jpg?w=200&h=200&q=100&fit=fill"
        }
       
    }
}

export default Author
